
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Field } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import jsPDF from "jspdf";
import moment from "moment";
import { Modal } from "bootstrap";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import KTFooter from "@/layout/footer/Footer.vue";
moment.locale("id");

const router = useRouter();

interface Book {
  detail: any;
  invoice: any;
  bankName: string;
  metodeName: string;
  sumberdana: string;
  nomorRekening: string;
  addInvoices: any;
  preview: any;
}

interface Send {
  data: any;
}

export default defineComponent({
  name: "invoices",
  components: {
    Field,
    Loading,
    Multiselect,
    KTFooter,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      // end loading overlay setting

      book: {
        detail: [],
        invoice: [],
        bankName: "",
        coaCode: "",
        sumberdana: "",
        sumberdanaa: "",
        coa: "Topup Manual",
        saldo_sumberdana: 0,
        metodeName: "Otomatis",
        nomorRekening: "",
        addInvoices: [],
        preview: [],
      } as Book,

      send: {
        data: [],
      } as Send,

      tableData: [
        {
          paymentStatus: "",
          number: "",
          schoolName: "",
          title: "",
          invoiceDate: "",
          totalAmount: "",
          _id: "",
        },
      ],
      ListSchool: [],
      ListMetode: [
        {
          label: "Otomatis",
          value: "otomatis",
        },
        {
          label: "Manual",
          value: "manual",
        },
      ],
      listcoa: [],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      wordCurrency: "",
      totalInvoice: "",
      amount: 0,
      discount: 0,
      tax: 0,
      shipping: 0,
      balanceCoa: 0,
      namaSekolah: "",
      totalAmount: 0,
      showFilter: false,
      showtutorial: false,
      totalPaid: "",
      totalUnPaid: "",
      listbankManual: [
        {
          initial: "BNI",
          no: "7000040408",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/BNI.svg",
        },
        {
          initial: "Jatim Syariah",
          no: "6201000466",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/bank_jatim_syariah.png",
        },
        {
          initial: "BSI",
          no: "8882609178",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/bsi.svg",
        },
        {
          initial: "NTBS",
          no: "0010100163276",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/NTBS.svg",
        },
      ],
      bankManual: "",
      minJtp: "",
      sumberdana: "",
      schoolId: "",
      schoolSelected: false,
      invoiceDate: "",
      checkedItem: [],
      detail: [],
      titip: [],
      listBank: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: "",
          deleteTime: "",
          bankName: "",
          bankCode: "",
          bankType: "",
          bankAccountNumber: "",
          bankAccountName: "",
          tutorial: "",
          status: true,
          description: "",
          _id: "",
        },
      ],
      listBankOtomatis: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: "",
          deleteTime: "",
          bankName: "",
          bankCode: "",
          bankType: "",
          bankAccountNumber: "",
          bankAccountName: "",
          tutorial: "",
          status: true,
          description: "",
          _id: "",
        },
      ],
      listBankManual: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: "",
          deleteTime: "",
          bankName: "",
          bankCode: "",
          bankType: "",
          bankAccountNumber: "",
          bankAccountName: "",
          tutorial: "",
          status: true,
          description: "",
          _id: "",
        },
      ],
      catatan:
        "Status pembayaran akan berubah secara otomatis setelah invoice terbayar",
      konfirm: true,
      paymentStatus: "UNPAID",
      viewInvoices: {
        number: "",
        vaNumber: "",
        invoiceDate: "",
        schoolName: "",
        expiredDate: "",
        address: "",
        phone: "",
        detail: [
          {
            name: "",
            qty: "",
            price: "",
            discount: "",
            tax: "",
            amount: "",
          },
        ],
        amount: null,
        tax: "",
        totalAmount: "",
      },
      date: "",
      Idinvoice: "",
      InvoicesById: [],
      btnBayar: true,
      statusBayar: true,
      doSubmit: true,
      error1: {
        selectBank1: "",
      },
      error2: {
        selectBank2: "",
      },
      note: "-",
      title: "",
      number: "",
      itemType: "",
      invoiceYear: "",

      targetKonfirmasi: {
        id: "",
        namaPengirim: "",
        rekTujuan: "",
        persetujuanFinance: "",
        rekPengirim: "",
        tanggal: "",
        note: "",
        paymentId: "",
        noRef: "",
        penanggungJawab: "",
        namaRektujuan: "",
        paidAmount: 0,
        imageBuktiTF: "",
        imageBuktiTFRslt: "",
      },
      errorBayarManual: {
        noRef: "",
        penanggungJawab: "",
        imageBuktiTF: "",
        namaPengirim: "",
        tanggal: "",
      },

      fileInputKey3: 0,
      imageBuktiTFDisplay: "",

      modalpaymentByID: "",
      tipeBayarManualSingle: false,
      buttonBayarManualSingle: false,
      resultBayarManual: false,
      successBayarManual: false,

      statusmodalbayarsinglepaid: false,
      statusmodalbayarsingleva: false,
      previewPembayaranSingle: false,
      showVaName: "",
      tutorial: "",
      showVaBank: "",
      showVaNumber: "",
      showFormCoa: false,
      showExpVaNumber: "",
      paymentStatusInit: false,
      schoolid2: "",
      role: "",
      name_user: "",
      id_user: "",
      detailInvoice: {},

      alertChange: "Metode Pembayaran Berhasil Dipilih",
      sumber: "",
      aksesBayarGabung: false,
      aksesBayar: false,
      aksesDelete: false,
      deleteByStatus: false,
      dataPayment: {
        paymentId: "",
        paymentMethod: "",
        paymentStatus: "",
        vaName: "",
        vaBank: "",
        showExpVaNumber: "",
        vaNumber: "",
        item: "",
      },
      checkDataPayment: {
        data: {
          callbacks: [{ coaCode: "" }],
          vaBank: "",
        },
      },
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    var route = useRoute();
    var schoolID = route.params.id + "";
    console.log(route.params.id, "haha");
    if (schoolID) {
      this.schoolId = schoolID;
      this.schoolSelected = true;
    }
    if (this.schoolId !== "") {
      this.getcombocoa(this.schoolId);
    }
    this.cekRoleAkses();

    this.getData(this.paging.size, this.paging.page);

    this.getResume(schoolID);

    this.getListSchool();
    this.getListBank("AUTOMATIC", "");
    this.getListBank("MANUAL", "");

    setCurrentPageBreadcrumbs("Invoices", ["Data"]);

    window.addEventListener("keypress", (e) => {
      if (e.keyCode == 13) {
        this.doSubmit == false;
        e.preventDefault();
        return false;
      }
    });
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    // print() {
    //   window.print();
    // },

    // printInvoicesById () {
    //   let pdfName = 'test';
    //   var doc = new jsPDF();
    //   doc.text("Hello World", 10, 10);
    //   doc.save(pdfName + '.pdf');
    // },
    getListBank(e, va) {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/payment/type/" + e)
        .then((resp) => {
          if (e == "AUTOMATIC") {
            this.listBankOtomatis = resp.data;
          } else {
            this.listBankManual = resp.data;
          }
          this.listBank = [...this.listBankOtomatis, ...this.listBankManual];
          console.log(resp.data, "lkjhh");
        })
        .catch((err) => {
          console.log(err);
          this.listBank = [];
        });
    },
    getTutorial(sd) {
      var a = "";
      this.listBank.forEach((element) => {
        if (this.book.metodeName == "Manual") {
          if (element.bankName == sd) {
            a = element.tutorial;
          }
        } else {
          if (element.bankCode == sd) {
            a = element.tutorial;
          }
        }
      });
      if (a == "" || a == null) {
        this.showtutorial = false;
      } else {
        this.showtutorial = true;
      }

      this.tutorial = a;
    },
    cekStatus(e, data) {
      // CLEAR DATA

      this.dataPayment.paymentId = data.paymentId || "";
      this.dataPayment.paymentMethod = data.paymentMethod || "";
      this.dataPayment.paymentStatus = data.paymentStatus || "";
      this.dataPayment.vaName = data.vaName || "";
      this.dataPayment.vaBank = data.vaBank || "";
      this.dataPayment.vaNumber = data.vaNumber || "";
      this.dataPayment.item = data.item || "";

      this.totalAmount = data.totalAmount || "";
      this.schoolid2 = data.schoolId || "";
      this.sumber = data.item;

      if (data.paymentStatus == "PAID") {
        this.paymentStatusInit = true;
      }
      var urlrole = "crmv2/main_invoice/invoice/psp/invoice";

      ApiService.getWithoutSlug(`${urlrole}/wait/${e}`)
        .then((resp) => {
          if (resp.data == true) {
            var modal = new Modal(document.getElementById("kt_modal_menunggu"));
            modal.show();
          } else {
            if (
              this.dataPayment.paymentStatus == null ||
              this.dataPayment.paymentStatus == ""
            ) {
              var modal = new Modal(document.getElementById("kt_modal_2"));
              modal.show();
            } else {
              ApiService.getWithoutSlug(`${urlrole}/payment_data/${e}`).then(
                // ApiService.getWithoutSlug(`${urlrole2}${e}`).then(
                (res2) => {
                  this.checkDataPayment = res2.data;
                  this.getcombocoa(data.schoolId);

                  if (res2.data.paymentMethod == "MANUAL") {
                    this.book.metodeName = "Manual";
                    // console.log(res2.data.data);

                    var init = "";
                    // this.bankManual = res2.data.data.vaBank;
                    // this.book.bankName = res2.data.data.vaBank;
                    this.getTutorial(data.vaBank);
                    this.listBank.forEach((element) => {
                      if (element.bankName == res2.data.data.vaBank) {
                        init = element.bankName;
                        this.targetKonfirmasi.rekTujuan =
                          element.bankAccountNumber;
                      }
                    });
                    this.targetKonfirmasi.namaRektujuan = init;
                    // this.targetKonfirmasi.rekTujuan = res2.data.data.vaNumber;
                    this.targetKonfirmasi.paidAmount =
                      res2.data.data.totalAmount;
                    this.targetKonfirmasi.id = res2.data.data.invoiceId;
                    this.Idinvoice = res2.data.data.invoiceId;

                    var modal = new Modal(
                      document.getElementById("kt_modal_2")
                    );
                    modal.show();
                    // this.paymentByID(met);
                  } else {
                    this.targetKonfirmasi.namaRektujuan = res2.data.data.vaName;
                    //  this.bankManual = res2.data.data.vaBank;
                    this.book.bankName = res2.data.data.vaBank;
                    this.targetKonfirmasi.rekTujuan = res2.data.data.vaNumber;
                    this.Idinvoice = res2.data.data.invoiceId;
                    var modal = new Modal(
                      document.getElementById("kt_modal_2")
                    );
                    this.getTutorial(data.vaBank);
                    this.dataPayment.item = data.item;
                    this.book.metodeName = "Otomatis";
                    console.log(resp);
                    this.bayarPerInvoiceId(data);
                    modal.show();
                  }
                }
              );
            }
          }
        })
        .catch((e) => {});
    },
    cekRoleAkses() {
      switch (this.role) {
        case "SUPER_ADMIN":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = true;
          break;
        case "ADMIN_FINANCE":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = true;
          break;
        case "SALES_LEADER":
          this.aksesBayarGabung = false;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "CS_LEADER":
          this.aksesBayarGabung = false;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "CS_MEMBER":
          this.aksesBayarGabung = false;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "SALES_AREA":
          this.aksesBayarGabung = false;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "IMPLEMENTATOR_LEADER":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = false;
          break;
        case "IMPLEMENTATOR_MEMBER":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = false;
          break;
        case "ADMIN_SCHOOL":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = false;
          break;
      }
    },

    changeMetode(prop) {
      // this.book.metodeName = prop
      console.log(prop.target.value);
      var metode = prop.target.value == "Manual" ? "MANUAL" : "AUTOMATIC";
      this.getListBank(metode, "");
    },

    //API-----------------------------------

    // manualPayment() {
    //   let bank = ""
    //   let invoicId = ""
    //   let metode = "MANUAL";
    //   ApiService.postWithoutSlug(
    //     `crmv2/main_invoice/invoice/office/invoice/pay?bankName=${this.book.bankName}&invoiceId=${this.targetKonfirmasi.id}&paymentMethod=MANUAL`
    //   ).then(resp => {

    //     const modal = new Modal(document.getElementById("kt_modal_konfirm_manual"));
    //     modal.show();

    //   }).catch(error => {
    //   })
    // },
    addPreview() {
      this.book.bankName = "";
      this.selectBank();
      if (this.book.detail.length < 2) {
        this.btnBayar = false;
        if (this.book.detail.length == 0) {
          this.statusBayar = false;
        } else {
          this.statusBayar = true;
        }
      } else {
        this.btnBayar = true;
        const router = useRouter();
        const toast = useToast();

        this.isLoading = true;
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var m = moment().format("DD/MM/YYYY");

        this.send.data = {
          invoiceDate: date,
          detail: [],
        };

        this.book.detail.forEach((element) => {
          element["detail"].forEach((ifile) => {
            this.send.data["detail"].push(ifile);
          });
        });

        ApiService.postWithData(
          "crmv2/main_invoice/invoice/psp/invoice/preview?schoolId=" +
            this.schoolId +
            "&title=Tagihan" +
            "&itemType=Package",
          this.send.data
        )
          .then((res) => {
            this.wordCurrency = res.data.wordCurrency;
            this.totalAmount = res.data.totalAmount;
            this.amount = res.data.amount;
            this.discount = res.data.discount;
            this.tax = res.data.tax;
            this.shipping = res.data.shipping;
            this.book.invoice = res.data;

            this.isLoading = false;
          })
          .catch((e) => {
            this.isLoading = false;
          });
      }
    },
    submitAddInvoices() {
      Swal.fire({
        title: "Apakah Anda Yakin akan menggabungkan invoices ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ok !",
        cancelButtonText: "Kembali",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.konfirmasi();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire("Cancelled", "Bayar Invoice terlebih dahulu", "error");

          this.book.bankName = "";
          this.error1.selectBank1 = "";
          // this.addPreview();
        }
      });
    },

    submitBayarManual() {
      // const modal = new Modal(document.getElementById("kt_modal_konfirm_manual"));
      // modal.show();
      Swal.fire({
        title:
          "Apakah Anda Yakin akan menggabungkan invoices dan membayar manual?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ok !",
        cancelButtonText: "Kembali",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.konfirmasiManual();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire("Cancelled", "Bayar Invoice terlebih dahulu", "error");

          this.book.bankName = "";
          this.error1.selectBank1 = "";
          // this.addPreview();
        }
      });
    },
    konfirmasi() {
      this.isLoading = true;

      // this.konfirm = false;

      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var m = moment().format("DD/MM/YYYY");

      this.book.addInvoices = {
        invoiceDate: date,
        detail: [],
      };
      var url =
        "crmv2/main_invoice/invoice/psp/invoice/pay?bankName=" +
        this.book.bankName +
        "&invoiceId=" +
        this.Idinvoice;

      if (this.role !== "SUPER_ADMIN") {
        url = "crmv2/main_invoice/invoice/";
      }

      var metod = this.book.metodeName == "Otomatis" ? "AUTOMATIC" : "MANUAL";
      this.book.addInvoices["detail"] = this.book.invoice.detail;
      ApiService.postWithData(
        "crmv2/main_invoice/invoice/psp/invoice?schoolId=" +
          this.schoolId +
          "&title=Tagihan" +
          "&itemType=Package",
        this.book.addInvoices
      )
        .then((res) => {
          this.Idinvoice = res.data._id;

          url =
            "crmv2/main_invoice/invoice/psp/invoice/pay?bankName=" +
            this.book.bankName +
            "&invoiceId=" +
            this.Idinvoice +
            "&paymentMethod=" +
            metod;

          ApiService.postWithoutSlug(url).then((res3) => {
            this.viewInvoices = res3.data;
            localStorage.setItem("listDetailInv", JSON.stringify(res3.data));

            this.$router.push("/sekolah/view-detail-invoices/multi-invoice");

            this.isLoading = false;
          });
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },

    changebankManual(e) {
      this.listBankManual.forEach((element, idx) => {
        if (element.bankName === e.target.value) {
          this.targetKonfirmasi.rekTujuan = element.bankAccountNumber;
          this.targetKonfirmasi.namaRektujuan = element.bankAccountName;
          this.book.bankName = element.bankName;
        }
      });
      console.log(this.targetKonfirmasi.rekTujuan, "fdghfh");

      this.getTutorial(e.target.value);
    },
    konfirmasiManual() {
      this.isLoading = true;
      const toast = useToast();

      // this.konfirm = false;

      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var m = moment().format("DD/MM/YYYY");

      this.book.addInvoices = {
        invoiceDate: date,
        detail: [],
      };
      var uu = "schoolId=" + this.schoolId;
      this.role == "SUPER_ADMIN" ? (uu = "schoolId=" + this.schoolId) : "";
      var url = "crmv2/main_invoice/invoice/psp/invoice";

      var metod = this.book.metodeName == "Otomatis" ? "AUTOMATIC" : "MANUAL";
      this.book.addInvoices["detail"] = this.book.invoice.detail;
      ApiService.postWithData(
        "crmv2/main_invoice/invoice/psp/invoice?schoolId=" +
          this.schoolId +
          "&title=Tagihan" +
          "&itemType=Package",
        this.book.addInvoices
      )
        .then((res) => {
          this.Idinvoice = res.data._id;
          this.targetKonfirmasi.id = res.data._id;

          ApiService.postWithoutSlug(
            url +
              "/pay?bankName=" +
              this.book.bankName +
              "&invoiceId=" +
              this.Idinvoice +
              "&paymentMethod=" +
              metod
          ).then((res3) => {
            this.showVaName = res3.data.vaName;
            this.showVaBank = res3.data.vaBank;
            this.targetKonfirmasi.namaRektujuan = res3.data.vaBank;
            this.targetKonfirmasi.rekTujuan = res3.data.vaNumber;
            this.targetKonfirmasi.paidAmount = res3.data.totalAmount;
            this.targetKonfirmasi.persetujuanFinance = res3.data.paymentStatus;
            this.targetKonfirmasi.paymentId = res3.data.paymentId;
            this.showVaNumber = res3.data.vaNumber;
            this.showExpVaNumber = res3.data.expiredDate;
            this.viewInvoices = res3.data;
            localStorage.setItem("listDetailInv", JSON.stringify(res3.data));
            this.targetKonfirmasi.paymentId = res3.data.paymentId;

            this.tipeBayarManualSingle = false;
            this.buttonBayarManualSingle = false;

            this.errorBayarManual.noRef = "";
            this.errorBayarManual.penanggungJawab = "";

            // this.$router.push("/superadmin/view-detail-invoices/multi-invoice");
            toast.success("invoice berhasil di gabungkan");

            this.resultBayarManual = false;
            this.targetKonfirmasi.noRef = "";
            this.targetKonfirmasi.penanggungJawab = "";

            const modal = new Modal(
              document.getElementById("kt_modal_konfirm_manual")
            );
            modal.show();

            this.isLoading = false;
          });
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    bantuanModal() {
      var modal = new Modal(document.getElementById("kt_modal_4"));
      // this.bayarPerInvoiceId(b);
      modal.show();
    },
    saveModalFormKonfirmasi() {
      const toast = useToast();
      if (
        this.targetKonfirmasi.noRef === "" ||
        this.targetKonfirmasi.namaPengirim === "" ||
        this.targetKonfirmasi.penanggungJawab === "" ||
        this.targetKonfirmasi.imageBuktiTF === "" ||
        this.targetKonfirmasi.tanggal === "" ||
        this.targetKonfirmasi.paidAmount === 0
      ) {
        if (this.targetKonfirmasi.noRef === "") {
          this.errorBayarManual.noRef = "Nomor Referensi Wajib Diisi";
        }
        if (this.targetKonfirmasi.namaPengirim === "") {
          this.errorBayarManual.namaPengirim =
            "Nama Rekening Pengirim Wajib Diisi";
        }
        if (this.targetKonfirmasi.penanggungJawab === "") {
          this.errorBayarManual.penanggungJawab =
            "Penanggung Jawab Wajib Diisi";
        }
        if (this.targetKonfirmasi.tanggal === "") {
          this.errorBayarManual.tanggal = "Tanggal Pembayaran Wajib Diisi";
        }
        if (this.targetKonfirmasi.imageBuktiTF === "") {
          this.errorBayarManual.imageBuktiTF = "File Tidak Boleh Kosong";
          toast.error("Form belum lengkap");
        }
        if (this.targetKonfirmasi.paidAmount === 0) {
          toast.error(
            "Untuk melakukan pembayaran nominal tagihan Tidak Boleh 0"
          );
          return false;
        }
      } else {
        this.isLoading = true;

        let formData = new FormData();
        formData.append("file", this.targetKonfirmasi.imageBuktiTF);
        let isi = {
          paymentId: this.targetKonfirmasi.paymentId,
          note: this.targetKonfirmasi.note,
          paymentDate: moment(this.targetKonfirmasi.tanggal).format(
            "YYYY-MM-DD"
          ),
          refNumber: this.targetKonfirmasi.noRef,
          paidAmount: this.targetKonfirmasi.paidAmount,
          bankNumbSender: "None",
          bankNameSender: this.targetKonfirmasi.namaPengirim,
          bankNumbTo: this.targetKonfirmasi.rekTujuan,
          bankNameTo: this.targetKonfirmasi.namaRektujuan,
          pic: this.targetKonfirmasi.penanggungJawab,
          imageReceipt: [""],
          status: "WAIT",
        };

        let datanew = {};
        var url = "crmv2/main_invoice/invoice/psp/";

        ApiService.postWithData(
          "crmv2/main_image/image/upload/" + this.targetKonfirmasi.paymentId,
          formData
        )
          .then((res) => {
            // cekSuccess = true;
            // toast.success("Berhasil Upload Foto Siswa");
            isi.imageReceipt = [res.data.imgUrl];
            let conjson = JSON.stringify(isi);
            // datanew =JSON.parse(isi)

            // setTimeout(() => this.getData(this.paging.size, this.paging.page), 2000);

            ApiService.postWithData(
              url + "invoice/confirm?invoiceId=" + this.targetKonfirmasi.id,
              conjson
            )
              .then((res3) => {
                // this.viewInvoices = res3.data;
                // localStorage.setItem("listDetailInv", JSON.stringify(res3.data));
                toast.success("Sukses Bayar Manual");
                // var modal2 = new Modal(document.getElementById("kt_modal_konfirm_manual"));
                // modal2.hide();
                // this.$router.push("/superadmin/history-pembayaran-manual");
                this.resultBayarManual = true;
                this.successBayarManual = true;
                this.isLoading = false;
              })
              .catch((e) => {
                this.isLoading = false;
                this.resultBayarManual = false;
                this.successBayarManual = false;
                const modal1 = new Modal(document.getElementById("kt_modal_2"));
                modal1.hide();
                this.book.metodeName = "Otomatis";
                toast.error("Gagal, " + e.response.data.detail);
              });
          })
          .catch((e) => {
            toast.error("Ukuran file melebihi 2MB");
            this.isLoading = false;
          });
      }
    },

    changeBank(event) {
      this.dataPayment.paymentId = "";
      this.dataPayment.paymentMethod = "";
      this.dataPayment.paymentStatus = "";
      this.dataPayment.vaName = "";
      this.dataPayment.vaBank = "";
      this.dataPayment.vaNumber = "";
      this.checkDataPayment = {
        data: {
          callbacks: [{ coaCode: "" }],
          vaBank: "",
        },
      };

      this.book.metodeName = "Otomatis";
      this.book.sumberdana = "";
      this.balanceCoa = 0;
      this.bankManual = "";
      this.targetKonfirmasi.rekTujuan = "";
      this.book.bankName = "";
      this.getListBank("AUTOMATIC", "");

      var modal = new Modal(document.getElementById("kt_modal_2"));
      // this.bayarPerInvoiceId(b);
      modal.show();
    },
    goToPageHistory() {
      this.$router.push("/superadmin/history-pembayaran-manual");
    },
    goToPageHistoryy() {
      location.reload();
    },
    saveModalKonfirmasi() {
      const toast = useToast();
      ApiService.postWithoutSlug(
        "crmv2/main_invoice/invoice/update_manual_payment/" +
          this.targetKonfirmasi.paymentId +
          "?process=MANUAL&refNumber=" +
          this.targetKonfirmasi.noRef +
          "&pic=" +
          this.targetKonfirmasi.penanggungJawab
      )
        .then((res3) => {
          // this.viewInvoices = res3.data;
          // localStorage.setItem("listDetailInv", JSON.stringify(res3.data));
          toast.success("Sukses Bayar Manual");
          var modal2 = new Modal(
            document.getElementById("kt_modal_konfirm_manual_fix")
          );
          modal2.hide();

          this.$router.push("/superadmin/history-pembayaran-manual");

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          toast.error("Gagal Bayar Manual");
        });
      // var modal1 = new Modal(document.getElementById("kt_modal_konfirm_manual"));
      // modal1.hide();
      // var modal2 = new Modal(document.getElementById("kt_modal_konfirm_manual_fix"));
      // modal2.show();
    },
    cancelModalKonfirmasi() {
      var modal = new Modal(
        document.getElementById("kt_modal_konfirm_manual_fix")
      );
      modal.hide();
    },
    bayarManualSingle(e) {
      // this.targetKonfirmasi.paymentId = res3.data.paymentId;
      if (e == "Otomatis") {
        var modal = new Modal(document.getElementById("kt_modal_2"));
        modal.show();
        this.paymentByID();
      } else {
        console.log("paymentId " + this.targetKonfirmasi.paymentId);

        this.tipeBayarManualSingle = true;
        this.buttonBayarManualSingle = false;
        this.errorBayarManual.namaPengirim = "";

        this.errorBayarManual.noRef = "";
        this.errorBayarManual.penanggungJawab = "";

        this.resultBayarManual = false;
        this.targetKonfirmasi.namaPengirim = "";
        this.targetKonfirmasi.noRef = "";
        this.targetKonfirmasi.penanggungJawab = "";
        this.targetKonfirmasi.namaRektujuan = this.returnAcontName(
          this.checkDataPayment.data.vaBank
        );
        this.targetKonfirmasi.rekTujuan = this.returnBankName(
          this.checkDataPayment.data.vaBank
        );
        var modal2 = new Modal(document.getElementById("kt_modal_2"));
        modal2.hide();
        const modal = new Modal(
          document.getElementById("kt_modal_konfirm_manual")
        );
        modal.show();
      }
    },
    batalFormKonfirmasi() {
      this.buttonBayarManualSingle = true;
      this.isLoading = false;
    },
    TutupCancelKonfirmasi() {
      // location.reload();
      this.getData(this.paging.size, this.paging.page);
      var data = document.getElementsByClassName("checkHistory");
      this.book.detail = [];

      for (let index = 0; index < data.length; index++) {
        if (data[index]["checked"] == true) {
          // data.setValue(false);
          data[index]["checked"] = false;
        }
      }
    },

    cekInvoiceById(id) {
      var toast = useToast();
      ApiService.getWithoutSlug(`crmv2/main_invoice/invoice/psp/invoice/${id}`)
        .then((resp) => {
          console.log(resp.data);
          this.detailInvoice = resp.data;
          var modal = new Modal(document.getElementById("kt_modal_preview"));
          modal.show();
        })
        .catch((err) => {
          toast.error(err.response.data.data);
        });
    },
    returnBankName(e) {
      var a = this.listBank.filter((item) => item.bankName === e);
      var c = "";
      this.listBank.forEach((element) => {
        if (element.bankName === e) {
          c = element.bankAccountNumber;
        }
      });
      // console.log(a[0].bankAccountNumber, "sejjjd");
      return c;
    },
    returnAcontName(e) {
      var a = this.listBank.filter((item) => item.bankName === e);
      // console.log(a[0].bankAccountNumber, "sejjjd");
      var c = "";
      this.listBank.forEach((element) => {
        if (element.bankName === e) {
          c = element.bankAccountName;
        }
      });
      // console.log(a[0].bankAccountNumber, "sejjjd");
      return c;
    },
    paymentByID() {
      var metode = this.book.metodeName;
      const toast = useToast();
      this.book.metodeName = metode;

      // if(this.balanceCoa >= this.totalAmount){
      //   return toast.error("Saldo tidak cukup untuk pembayaran invoice");
      // }
      if (this.doSubmit) {
        console.log(this.sumber, "yyyyyyyyyyyyyyy");
        if (this.book.sumberdana == "") {
          this.error2.selectBank2 = "Sumber Dana Harus Diisi";
        }
        if (this.book.bankName == "") {
          this.error1.selectBank1 = "Bank Harus Diisi";
        }
        if (this.sumber == "Topup Manual") {
          if (this.book.bankName == "") {
            return toast.error("Form Kosong wajib diisi");
          }
        }
        // if(this.book.sumberdana == ""){
        //   return toast.error("Form Kosong wajib diisi")
        // }
        // if(this.book.bankName == ""){
        //   return toast.error("Form Kosong wajib diisi")
        // }

        if (this.sumber == "Topup Manual") {
          if (this.balanceCoa < this.totalAmount && !this.showFormCoa) {
            var message =
              "Saldo Coa " +
              this.book.sumberdana +
              " tidak cukup untuk pembayaran invoice";
            this.book.sumberdana = "";
            return toast.error(message);
          }
        }
        if (this.showFormCoa === true) {
          this.book.sumberdana = "";
        }

        //    if(this.balanceCoa <= this.totalAmount){
        //   return toast.error("Saldo tidak cukup untuk pembayaran invoice");
        // }
        // if (this.book.sumberdana == "") {
        //   this.error2.selectBank2 = "Sumber Dana Harus Diisi";
        // }

        if (this.book.bankName == "") {
          this.error1.selectBank1 = "Bank Harus Diisi";
        } else {
          const router = useRouter();
          const route = useRoute();
          this.isLoading = true;
          const metod = metode == "Otomatis" ? "AUTOMATIC" : "MANUAL";
          var url =
            "crmv2/main_invoice/invoice/psp/invoice/pay?bankName=" +
            this.book.bankName +
            "&invoiceId=" +
            this.Idinvoice +
            "&paymentMethod=" +
            metod +
            "&coaCode=" +
            this.book.sumberdana;

          ApiService.postWithoutSlug(url)
            .then((res) => {
              this.previewPembayaranSingle = false;
              this.dataPayment.paymentMethod = res.data.paymentMethod;
              this.dataPayment.paymentStatus = res.data.paymentStatus;
              this.dataPayment.vaName = res.data.vaName;
              this.dataPayment.vaBank = res.data.vaBank;
              this.dataPayment.vaNumber = res.data.vaNumber;
              this.dataPayment.showExpVaNumber = res.data.showExpVaNumber;
              this.showExpVaNumber = res.data.expiredDate;

              var urlrole_cekpayment_ =
                "crmv2/main_invoice/invoice/psp/invoice";

              ApiService.getWithoutSlug(
                `${urlrole_cekpayment_}/payment_data/${this.Idinvoice}`
              ).then(
                // ApiService.getWithoutSlug(`${urlrole2}${e}`).then(
                (res2) => {
                  this.checkDataPayment = res2.data;
                }
              );
              this.book.preview = res.data;

              if (metod == "MANUAL") {
                this.statusmodalbayarsingleva = true;
                this.showVaName = res.data.vaName;
                this.showVaBank = res.data.vaBank;
                // this.targetKonfirmasi.namaRektujuan = res.data.vaBank;
                // this.targetKonfirmasi.rekTujuan = res.data.vaNumber;
                this.targetKonfirmasi.paidAmount = res.data.totalAmount;
                this.targetKonfirmasi.persetujuanFinance =
                  res.data.paymentStatus;
                this.targetKonfirmasi.paymentId = res.data.paymentId;
                this.showVaNumber = res.data.vaNumber;
                this.showExpVaNumber = res.data.expiredDate;
                console.log("man");
                this.isLoading = false;
                this.error1.selectBank1 = "";
                this.error2.selectBank2 = "";
              } else {
                this.isLoading = false;
                this.error1.selectBank1 = "";
                this.error2.selectBank2 = "";
                toast.success(this.alertChange);
                // setTimeout(
                //   function (scope) {
                //     location.reload();
                //   },
                //   3000,
                //   this
                // );
                // this.$router.push("/superadmin/invoices");
                // this.batalFormKonfirmasi()
              }
            })
            .catch((e) => {
              this.isLoading = false;
              toast.error("Kode Coa kosong, pembayaran dibatalkan");
            });
        }
      }
    },
    getInvoicesById() {
      const toast = useToast();

      this.isLoading = true;

      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/invoice/" + this.Idinvoice
      )
        .then((response) => {
          this.InvoicesById = response.data;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          toast.error(response.data.detail);

          this.isLoading = false;
        });
    },

    getcombocoa(e) {
      console.log(e, "cuakss");
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/psp/invoice/coa/combo_coa" + "/" + e
      ).then(({ data }) => {
        console.log(data, "asasaas");
        this.listcoa = data;
      });
    },

    getbalance(e) {
      const toast = useToast();
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/psp/invoice/coa/balance" +
          "/" +
          this.schoolid2 +
          "?coaCode=" +
          e
      )
        .then(({ data }) => {
          if (data.balance < this.totalAmount && !this.showFormCoa) {
            var message =
              "Saldo Coa " +
              this.book.sumberdana +
              " tidak cukup untuk pembayaran invoice";
            this.book.sumberdana = "";
            this.balanceCoa = 0;
            return toast.error(message);
          }
          this.balanceCoa = data.balance;
        })

        .catch((error) => {});
      // then(({ data }) => {
      //   console.log(data, "asasaas");
      //   this.listcoa = data;
      // });
    },

    getListSchool() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.ListSchool = data;
        }
      );
    },
    getResume(e) {
      let schoolId = "";
      if (this.schoolId) {
        schoolId = "schoolId=" + this.schoolId + "&";
      } else {
        schoolId = "schoolId" + e;
      }

      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/psp/invoice/finance/resume?" + schoolId
      )
        .then(({ data }) => {
          this.totalInvoice = data.totalInvoice;
          this.totalPaid = data.totalPaid;
          this.totalUnPaid = data.totalUnPaid;
          this.minJtp = data.minJtp;
        })
        .catch(({ response }) => {});
    },
    getData(size, page) {
      console.log(page, size);
      this.isLoading = true;
      let schoolId = "";
      if (this.schoolId) {
        schoolId = this.schoolId;
      } else {
        schoolId;
      }

      let paymentStatus = "";
      if (this.paymentStatus) {
        paymentStatus = "&paymentStatus=" + this.paymentStatus;
      } else {
        paymentStatus;
      }

      let search1 = "";
      if (this.title) {
        search1 = this.title;
      } else {
        search1;
      }

      let search2 = "";
      if (this.itemType) {
        search2 = this.itemType;
      } else {
        search2;
      }

      let search3 = "";
      if (this.number) {
        search3 = this.number;
      } else {
        search3;
      }

      let search4 = "";
      if (this.invoiceYear) {
        search4 = this.invoiceYear;
      } else {
        search4;
      }

      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/psp/invoice/all?schoolId=" +
          schoolId +
          "&paymentStatus=" +
          this.paymentStatus +
          "&title=" +
          search1 +
          "&itemType=" +
          search2 +
          "&number=" +
          search3 +
          "&invoiceYear=" +
          search4 +
          "&page=" +
          page +
          "&size=" +
          size +
          "&sort=updateTime&dir=-1"
      )
        .then(({ data }) => {
          if (data.content.length === 0) {
            this.namaSekolah = this.namaSekolah;
          } else {
            this.namaSekolah = data.content[0].schoolName;
          }
          this.tableData = data.content || null;
          this.paging.page = data.page || null;
          this.paging.size = data.size || null;
          this.paging.totalElements = data.totalElements || null;
          this.paging.totalPages = data.totalPages || null;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    deleteRow(item) {
      const toast = useToast();

      ApiService.delete("crmv2/main_invoice/invoice/psp/invoice/" + item._id)
        .then((res) => {
          toast.success("Hapus Berhasil");

          this.getData(this.paging.size, this.paging.page);
        })

        .catch((error) => {
          toast.error("Gagal Hapus");
        });
    },

    //handle selected/ button click-----------------------------------

    handleSelected(index, item) {
      var data = document.getElementsByClassName("checkHistory");
      this.book.detail = [];

      for (let index = 0; index < data.length; index++) {
        if (data[index]["checked"] == true) {
          this.book.detail.push(data[index]["_value"]);
        }
      }
    },
    bayarPerInvoiceId(item) {
      this.book.bankName = "";
      this.previewPembayaranSingle = false;
      if (item.paymentStatus == "UNPAID") {
        if (item.vaNumber) {
          this.statusmodalbayarsinglepaid = false;
          if (this.getDateNow() <= item.expiredDate) {
            this.statusmodalbayarsingleva = true;
            this.book.metodeName = "Otomatis";
            this.showVaName = item.vaName;
            this.showVaBank = item.vaBank;
            this.book.bankName = item.vaBank;
            // this.targetKonfirmasi.namaRektujuan = item.vaBank;
            // this.targetKonfirmasi.rekTujuan = item.vaNumber;
            this.targetKonfirmasi.paidAmount = item.totalAmount;
            this.showVaNumber = item.vaNumber;
            this.showExpVaNumber = item.expiredDate;
          } else {
            this.statusmodalbayarsingleva = false;
          }
        } else {
          this.statusmodalbayarsinglepaid = false;
          this.statusmodalbayarsingleva = false;
        }
      } else {
        this.statusmodalbayarsingleva = false;
        this.statusmodalbayarsinglepaid = true;
      }
      this.Idinvoice = item._id;
      this.targetKonfirmasi.id = item._id;
      this.targetKonfirmasi.paymentId = item.paymentId;
    },
    handleDownload(index, item) {
      const toast = useToast();
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/psp/invoice/" +
          this.schoolId +
          "/data_invoice.xlsx"
      )
        .then((res) => {
          let fileUrl = window.URL.createObjectURL(res.data);
          let fileLink = document.createElement("a");

          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "import-excel-template.xls");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((e) => {
          toast.error(e.res.data.detail);
        });
    },
    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    selectBank() {
      if (this.book.bankName == "") {
        document
          .getElementById("submitAddInvoices")
          ?.setAttribute("disabled", "disabled");
        document
          .getElementById("submitBayarManual")
          ?.setAttribute("disabled", "disabled");
      } else {
        document
          .getElementById("submitAddInvoices")
          ?.removeAttribute("disabled");
        document
          .getElementById("submitBayarManual")
          ?.removeAttribute("disabled");
      }
    },
    pilihSekolah(event) {
      if (event) {
        this.getcombocoa(event);
        this.schoolId = event;
        this.schoolSelected = true;
        localStorage.setItem("sekolahIdMenuInvoice", event);
      } else {
        this.schoolId = "";
        this.schoolSelected = false;
        localStorage.removeItem("sekolahIdMenuInvoice");
      }
      // this.getQtyStudent();

      this.getData(this.paging.size, this.paging.page);

      var data = document.getElementsByClassName("checkHistory");
      this.book.detail = [];

      for (let index = 0; index < data.length; index++) {
        if (data[index]["checked"] == true) {
          // data.setValue(false);
          data[index]["checked"] = false;
        }
      }
    },
    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changeDate(date) {
      return moment(date, "yyyy-mm-dd").format("dd-mm-yyyy");
    },
    goBack() {
      this.konfirm = true;

      this.getData(this.paging.size, this.paging.page);
    },
    clickReset() {
      this.getData(this.paging.size, 0);

      (this.book.invoice = []),
        (this.book.bankName = ""),
        (this.book.sumberdana = ""),
        (this.error1.selectBank1 = ""),
        (this.error2.selectBank2 = ""),
        (this.totalAmount = 0),
        // (this.book.metodeName = "Otomatis"),
        (this.book.nomorRekening = ""),
        (this.bankManual = ""),
        (this.alertChange = "Metode Pembayaran Berhasil Dipilih"),
        (this.paymentStatusInit = false),
        (this.balanceCoa = 0),
        // (this.targetKonfirmasi.id = ""),
        // (this.targetKonfirmasi.namaRektujuan = ""),
        // (this.targetKonfirmasi.rekTujuan = "")

        (this.dataPayment.item = "");
      this.checkDataPayment = {
        data: {
          callbacks: [{ coaCode: "" }],
          vaBank: "",
        },
      };

      this.book.metodeName = "Otomatis";
      this.book.sumberdana = "";
      this.balanceCoa = 0;
      this.showFormCoa = false;
      this.bankManual = "";
      this.targetKonfirmasi.rekTujuan = "";
      this.book.bankName = "";
      this.targetKonfirmasi = {
        id: "",
        namaPengirim: "",
        rekTujuan: "",
        persetujuanFinance: "",
        rekPengirim: "",
        tanggal: "",
        note: "",
        paymentId: "",
        noRef: "",
        penanggungJawab: "",
        namaRektujuan: "",
        paidAmount: 0,
        imageBuktiTF: "",
        imageBuktiTFRslt: "",
      };
    },
    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      this.getData(this.paging.size, 0);
    },
    checkStatus(val) {
      if (val == "PAID") {
        return false;
      } else {
        return true;
      }
    },
    handleFotoImageBuktiTF(event) {
      const toast = useToast();
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        if (
          myArray[1] == "jpg" ||
          myArray[1] == "jpeg" ||
          myArray[1] == "png"
        ) {
          this.imageBuktiTFDisplay = URL.createObjectURL(event.target.files[0]);
          this.targetKonfirmasi.imageBuktiTF = event.target.files[0];
          this.errorBayarManual.imageBuktiTF = "";
          // this.targetDataUploadExcel.fileexcel = event.target.files[0];
        } else {
          toast.error("File Hanya .jpg .jpeg .png");
          this.fileInputKey3++;
          this.errorBayarManual.imageBuktiTF = "File Hanya .jpg .jpeg .png";
          this.targetKonfirmasi.imageBuktiTF = "";
          // this.errorUploadExcel.file = "File Wajib Isi dan File Hanya .xlsx";
        }
      } else {
        toast.error("File Kosong");
        this.errorBayarManual.imageBuktiTF = "File Tidak Boleh Kosong";
        this.targetKonfirmasi.imageBuktiTF = "";
        this.fileInputKey3++;
      }
    },

    //format-----------------------------------

    formatDate(value) {
      return moment(value).format("DD MMMM YYYY");
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatMinustoPositive(value) {
      let result = 0;
      if (value < 0) {
        result = Math.abs(value);
      } else {
        result = value;
      }
      return result;
    },

    formatTittle(value) {
      let cek = value.includes("TANGGAL");
      if (cek == true) {
        const myArray = value.split("TANGGAL");
        return myArray[0];
      } else {
        // checkHistory;
        return value;
      }
    },
    getDateNow() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var rslt = yyyy + "-" + mm + "-" + dd;
      return rslt;
    },
  },
});

function getInvoicesById() {
  throw new Error("Function not implemented.");
}
